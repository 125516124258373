import React, { useState } from "react"
import Layout from "../components/navigation/layout"
import map from "../assets/images/map.png"
import protection from "../assets/images/fuelcard2.png"
import notifications from "../assets/images/notifications.png"
import dashboard from "../assets/images/dashboard.png"
import equipment from "../assets/images/equipment2.png"
import device from "../assets/images/elddevice.png"
import ContactForm from "../components/ContactForm"
import Seo from "../components/seo"

function Services() {
  const [toggleState, setToggleState] = useState(1)
  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <Layout>
      <Seo title="Our Services" />
      <div className="page-heading-services header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Our Services</h1>
              <span>Look what we offer</span>
            </div>
          </div>
        </div>
      </div>
      <div className="single-services">
        <div className="container">
          <div className="row" id="tabs">
            <div className="col-md-4">
              <ul>
                <li className={toggleState === 1 ? "ui-tabs-active" : null}>
                  <button className="pointer_service" onClick={() => toggleTab(1)}>Assets Map<i
                    className="fa fa-angle-right" /></button>
                </li>
                <li className={toggleState === 2 ? "ui-tabs-active" : null}>
                  <button className="pointer_service" onClick={() => toggleTab(2)}>Fuel Card Protection<i
                    className="fa fa-angle-right" /></button>
                </li>
                <li className={toggleState === 3 ? "ui-tabs-active" : null}>
                  <button className="pointer_service" onClick={() => toggleTab(3)}>Start and Notifications<i
                    className="fa fa-angle-right" /></button>
                </li>
                <li className={toggleState === 4 ? "ui-tabs-active" : null}>
                  <button className="pointer_service" onClick={() => toggleTab(4)}>Dashboards<i
                    className="fa fa-angle-right" /></button>
                </li>
                <li className={toggleState === 5 ? "ui-tabs-active" : null}>
                  <button className="pointer_service" onClick={() => toggleTab(5)}>GPS, RFID, IR equipment<i
                    className="fa fa-angle-right" /></button>
                </li>
                <li className={toggleState === 6 ? "ui-tabs-active" : null}>
                  <button className="pointer_service" onClick={() => toggleTab(6)}>Electronic Logging Device<i
                    className="fa fa-angle-right" /></button>
                </li>
              </ul>
            </div>
            <div className="col-md-8">
              <section className="tabs-content">
                {toggleState === 1 ?
                  <article>
                    <img src={map} alt="map" />
                    <h4>Assets Map</h4>
                    <p> Displaying <em>integrated information</em> of real time movement of all company assets, HOS
                      data,
                      road conditions collected from multiple data sources.
                      <br /><br /> Bringing the data from your current dispatch and GPS tracking systems into a <em>single
                        management console</em>.<br />Displaying and analyzing <em>asset movement</em> history.
                      Detecting
                      and indicating stops, viewing history by specific dates and by date ranges.
                      <br /><br /> <em>Calculating distances and managing routes</em> directly on the map. Filtering
                      assets by
                      cargo status, asset type, order information. Viewing temperature and equipment status information.
                      Managing landmarks for quick asset lookup.</p>
                  </article> : null}
                {toggleState === 2 ?
                  <article>
                    <img className="fuelimage" src={protection} alt="protection" />
                    <h4>Fuel Card Protection</h4>
                    <p><em>Protecting your business</em> from fuel card related fraud. Providing fraud prevention
                      measures
                      with automated detection of card misuse. Issuing a one time PIN code to the driver upon a fuel
                      request.
                      <br /> <br /><em>Preventing misuse</em> of the card by comparing the truck and the driver against
                      the card
                      usage database. Comparing the location of the truck at the time of the fuel request against a
                      database of authorized fuel stations.
                      <br /> <br /><em>Increasing security</em> by optionally activating the card only for the duration
                      of the
                      fuelling stop and deactivating the card once the truck leaves the fuel station or once an hour
                      passes after the last fuel request.
                      <br /><br /><em>Reducing overall fuel fraud</em> by comparing fuel events against truck fuel
                      levels,
                      detecting inconsistencies and anomalies.</p>
                  </article> : null}
                {toggleState === 3 ?
                  <article>
                    <img className="fuelimage" src={notifications} alt="notifications" />
                    <h4>Start and Notifications</h4>
                    <p><em>Providing notifications</em> to the personnel about assets entering or leaving yard and other
                      locations. Preventing missed connections, maintaining asset servicing schedules.
                      <br /><br /><em>Improving efficiencies</em> and reducing costs with movement triggers used
                      strategically
                      for detecting long periods of inactivity in local assets.
                      <br /><br /><em>Preventing loss and misuse</em> of trailers with signal triggers, detecting long
                      standing
                      equipment.
                      <br /><br />Providing customers with up to date information on cargo location based on periodic
                      timer
                      fired email triggers.</p>
                  </article> : null}
                {toggleState === 4 ?
                  <article>
                    <img src={dashboard} alt="dashboard" />
                    <h4>Reporting and Dashboards</h4>
                    <p><em>Reporting</em> based on the integrated information collected from all systems, including
                      dispatch, GPS, HOS, fuel card management. Reporting on the profitability, revenues, expenses,
                      processes, assets, resources, clients, etc.
                      <br /><br />Generating information to update third party systems, such as dispatch, truck
                      management,
                      accounting and others.
                      <br /><em>Providing dashboards</em> based on the generated reporting information.</p>
                  </article> : null}
                {toggleState === 5 ?
                  <article>
                    <img src={equipment} alt="equipment" />
                    <h4>GPS, RFID, IR equipments</h4>
                    <p> Providing <em>IR equipment</em> for maintaining precise information on hooking transports
                      together from
                      individual trucks and trailers.
                      <br /> <br /> Providing <em>GPS</em> equipment for tracking location of fleet assets.<br />
                      Providing cargo sensors, door sensors, temperature sensors.<br />
                      Providing <em>RFID tags</em> and readers for warehouse management.</p>
                  </article> : null}
                {toggleState === 6 ?
                  <article>
                    <img src={device} alt="device eld" />
                    <h4>Electronic Logging Device</h4>
                    <p>Providing easy to use <em>ELD/HOS solution</em>, compliant with FMCSA regulations eCFR Title 49,
                      part 395.<br /><br />
                      Providing a direct link to the truck ECM through a WiFi enabled J1939 <em>ELD connector</em> with
                      bi-directional interface.<br /><br />
                      Optionally providing ELD/HOS through a GPS/3G module with <em>on-board memory</em>.<br /><br />
                      Providing ELD/HOS service, <em>secure</em> and compliant data storage.<br />
                      <em>IFTA</em> calculations based on the miles and fuel purchases/receipts recorded in each state,
                      province, territory.<br />
                    </p>
                  </article> : null}
              </section>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </Layout>
  )
}

export default Services